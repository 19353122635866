import { useAuthStore } from "~/store/auth";

export default defineNuxtRouteMiddleware((to, from) => {
  const authStore = useAuthStore();
  const nuxtApp = useNuxtApp();
  const route = useRoute();

  if (!authStore.loggedIn) {
    // setCookie(event, "Redirect", ++counter)
    useCookie("Redirect", {
      default: () => route.fullPath,
    });

    return navigateTo(nuxtApp.$i18nPath("/login"));
  }
});
